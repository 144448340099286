import React, {useEffect} from 'react';
import shallow from 'zustand/shallow';
import Helmet from 'react-helmet';
import {Link} from 'gatsby';
import Layout from '../components/Layout';
import {usePhotoStore} from '../store';

const About = () => {
  const {userInfo, getUserInfo} = usePhotoStore(
    (state) => ({
      userInfo: state.userInfo,
      getUserInfo: state.getUserInfo,
    }),
    shallow
  );

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      getUserInfo();
    }

    return () => {
      mounted = false;
    };
  }, [getUserInfo]);

  const aboutMessage =
    userInfo &&
    userInfo.extraText &&
    userInfo.extraText.content[0] &&
    userInfo.extraText.content[0].content[0] &&
    userInfo.extraText.content[0].content[0].value;

  return (
    userInfo && (
      <Layout>
        <Helmet
          title="About me"
          meta={[
            {
              name: 'description',
              content: 'About me',
            },
          ]}
        />
        <section id="two">
          <div className="inner">
            <header className="major">
              <h3 dangerouslySetInnerHTML={{__html: aboutMessage}} />
            </header>
            <ul className="actions">
              <li>
                <Link className="button next" to="/projects">
                  See my projects!
                </Link>
              </li>
            </ul>
          </div>
        </section>
      </Layout>
    )
  );
};

export default About;
